function makeUrlAbsolute(url) {
  if (!url) return null;

  const isAbsolute = new RegExp('^(?:[a-z]+:)?//', 'i');
  if (isAbsolute.test(url)) {
    return url;
  }

  return `https://${url}`;
}

export default makeUrlAbsolute;
