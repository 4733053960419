import React from 'react';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Heading, Row, Col } from 'components';
import logo from 'assets/images/logo_white.png';
import { iconList as icons } from 'assets';
import Content, {
  ContactInformations,
  LogoLink,
  Navigation,
  Rights,
  Social,
} from './Footer.styled';

function Footer() {
  const { t } = useTranslation();
  return (
    <Content>
      <Container>
        <Row>
          <Col size={{ xs: 1 / 1, lg: 1 / 4 }}>
            <LogoLink href="#">
              <img src={logo} alt="IPMA logo" />
            </LogoLink>
            <Social>
              <a
                href="https://www.facebook.com/IPMA.World"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG wrapper="span" src={icons.facebook} />
              </a>
              <a
                href="https://www.linkedin.com/company/ipma-international-project-management-association/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ReactSVG wrapper="span" src={icons.linkedin} />
              </a>
              <a href="https://twitter.com/ipmaglobal" target="_blank" rel="noopener noreferrer">
                <ReactSVG wrapper="span" src={icons.twitter} />
              </a>
            </Social>
          </Col>
          <Col size={{ xs: 1 / 1, lg: 2 / 4 }}>
            <ContactInformations>
              <Heading as="h6" color="inherit">
                International Project Management Association (IPMA)
              </Heading>
              <p>
                <ReactSVG wrapper="span" src={icons.marker} />
                IPMA Central Secretariat, the Netherlands
              </p>
              <p>
                <ReactSVG wrapper="span" src={icons.mail} />
                <a href="mailto:info@ipma.world">
                  <strong>info@ipma.world</strong>
                </a>
              </p>
              <p>UID-Nummer: CHE-113.951.307</p>
            </ContactInformations>
          </Col>
          <Col size={{ xs: 1 / 1, lg: 1 / 4 }}>
            <Navigation>
              <Heading as="h6" color="inherit">
                Information
              </Heading>
              <ul>
                <li>
                  <a
                    href="https://www.ipma.world/about-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('navigation.footer.about')}
                  </a>
                </li>
                <li>
                  <a href="https://www.ipma.world/blog" target="_blank" rel="noopener noreferrer">
                    {t('navigation.footer.blog')}
                  </a>
                </li>
                <li>
                  <Link to="/terms-of-service">{t('navigation.footer.terms')}</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">{t('navigation.footer.policy')}</Link>
                </li>
                <li>
                  <Link to="/cookie-policy">{t('navigation.footer.cookie')}</Link>
                </li>
                <li>
                  <Link to="/contact">{t('navigation.footer.contact')}</Link>
                </li>
              </ul>
            </Navigation>
          </Col>
        </Row>
        <Rights>
          <Row>
            <Col size={{ xs: 1 / 1, md: 3 / 4 }}>
              <p>Copyright 2024 - International Project Management Association</p>
            </Col>
            <Col size={{ md: 1 / 4 }} visible={{ xs: false, md: true }}>
              <a
                className="w-full text-right"
                href="https://develtio.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Designed by Develtio
              </a>
            </Col>
          </Row>
        </Rights>
      </Container>
    </Content>
  );
}

export default Footer;
