import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { removeAlertCreator, resendRegistrationActivateCreator } from 'store/actionsCreators';
import { Button, Alert, ResendAlert } from 'components';
import { Margin } from 'styled-components-spacing';
import { useTranslation } from 'react-i18next';

function Alerts({ alerts, type, removeAlert, resendRegistrationActivate }) {
  const { t } = useTranslation();
  const [alert, setAlert] = useState(null);

  const handleRemoveAlert = (item) => {
    removeAlert(item);
  };

  const resendActivationEmail = () => {
    resendRegistrationActivate();
    handleRemoveAlert(alert);
  };

  useEffect(() => {
    const filteredAlerts = alerts.filter((item) => item.type === type);
    if (filteredAlerts.length) {
      window.scrollTo(0, 0);
      setAlert(filteredAlerts[0]);
      const timeout = setTimeout(() => handleRemoveAlert(filteredAlerts[0]), 10000);

      return () => {
        handleRemoveAlert(filteredAlerts[0]);
        clearTimeout(timeout);
      };
    }

    if (!filteredAlerts.length) {
      setAlert(null);
    }

    return undefined;
  }, [alerts]);

  if (!alert) {
    return null;
  }

  if (alert.list && alert.list.length) {
    return (
      <Margin bottom={{ xs: 32, md: 42 }}>
        <Alert type={alert.status}>
          <ul>
            {alert.list.map((item, index) => (
              <li key={`alert-errors-${index}`}>{item}</li>
            ))}
          </ul>
        </Alert>
      </Margin>
    );
  }

  if (alert.text === t('page.auth.register.accountInactive')) {
    return (
      <Margin bottom={{ xs: 32, md: 42 }}>
        <ResendAlert type={alert.status}>
          {alert.text}
          <Button onClick={() => resendActivationEmail()} resend>
            {t('page.auth.register.resendActivationEmail')}
          </Button>
        </ResendAlert>
      </Margin>
    );
  }

  return (
    <Margin bottom={{ xs: 32, md: 42 }}>
      <Alert type={alert.status}>{alert.text}</Alert>
    </Margin>
  );
}

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      text: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  type: PropTypes.string.isRequired,
  removeAlert: PropTypes.func.isRequired,
  resendRegistrationActivate: PropTypes.func.isRequired,
};

Alerts.defaultProps = {
  alerts: [],
};

const mapStateToProps = ({ alerts }) => ({ alerts });
const mapDispatchToProps = (dispatch) => ({
  removeAlert: (payload) => dispatch(removeAlertCreator(payload)),
  resendRegistrationActivate: () => dispatch(resendRegistrationActivateCreator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
