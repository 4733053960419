import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Margin, Padding } from 'styled-components-spacing';
import {
  PageContent,
  Container,
  Heading,
  Col,
  HeadingWrapper,
  Cms,
  Row,
  UserList,
  IconText,
  Map,
  HeaderBrand,
  Button,
  Breadcrumbs,
  Modal,
  Alerts,
  BoxList,
  ThumbnailCard,
} from 'components';
import {
  loadListViewCreator,
  removeListViewCreator,
  applyUserToMaCreator,
} from 'store/actionsCreators';
import { connect } from 'react-redux';
import { getAvatar, globalPropTypes, newLineToBr, parseUrlTitle } from 'helpers';
import { useAuthLock } from 'hooks';
import { isLogin } from '../../../../api';
import makeUrlAbsolute from '../../../../helpers/makeUrlAbsolute';

const initialBreadcrumbs = [
  {
    title: 'navigation.breadcrumbs.app.ma',
    url: '/ipma-associations',
  },
];

const View = ({ loadView, removeView, view, history, userMa, applyUserToMa, ...restProps }) => {
  const { t } = useTranslation();
  let { breadcrumbs } = restProps;
  let { id } = useParams();
  const { name, id: urlId } = useParams();
  const [renderAuthLock] = useAuthLock(history);

  if (!id && userMa) {
    id = userMa.id;
  }

  if (!breadcrumbs) {
    breadcrumbs = initialBreadcrumbs;
  }

  const {
    title,
    description,
    individualMembershipsDescription,
    individualMembershipsWebsite,
    organisationMembershipsDescription,
    photo,
    usersRole,
    events,
    youngCrew,
  } = view;

  useEffect(() => {
    loadView(id, history);

    return () => {
      removeView();
    };
  }, []);

  useEffect(() => {
    if (!name && view && view.title && urlId) {
      history.replace({ pathname: `/ipma-associations/${id}/${parseUrlTitle(view.title)}` });
    }
  }, [view]);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const renderDescription = (_name, content, extra = undefined) => {
    if (!_name && !content) return null;

    return (
      <Margin bottom={{ xs: 32, xl: 48 }}>
        {_name && (
          <HeadingWrapper>
            <Heading as="h2" size="h4">
              {_name}
            </Heading>
          </HeadingWrapper>
        )}

        {content && (
          <Cms>
            <p dangerouslySetInnerHTML={{ __html: newLineToBr(content) }} />
          </Cms>
        )}

        {extra}
      </Margin>
    );
  };

  const renderContactDetails = (target) => (
    <Padding all="20" className="bg-gray-100">
      <Margin bottom="16">
        <Heading as="h3" size="h6">
          {t(`page.singleMa.contact${target.charAt(0).toUpperCase() + target.slice(1)}`)}:
        </Heading>
      </Margin>

      {view[target].phone && (
        <Margin bottom="12">
          <a href={`tel:${view[target].phone}`}>{view[target].phone}</a>
        </Margin>
      )}
      {view[target].email && (
        <Margin bottom="12">
          <a href={`mailto:${view[target].email}`} className="link link--16 word-break">
            {view[target].email}
          </a>
        </Margin>
      )}
      {view[target].website && (
        <a href={view[target].website} className="link link--16 word-break">
          {view[target].website}
        </a>
      )}
    </Padding>
  );

  const renderPlace = (target) => {
    if (!view[target].place) return null;

    return (
      <Padding all="20">
        <IconText top icon="marker" text={view[target].place} />
      </Padding>
    );
  };

  const renderMap = (target) => {
    if (!view[target].lat || !view[target].lng) return null;

    return <Map lat={view[target].lat} lng={view[target].lng} />;
  };

  const renderContact = (target) => (
    <>
      {view && view[target] && view[target].place && view[target].phone ? (
        <Margin bottom="32">
          <div className="border-xl">
            {renderContactDetails(target)}
            {renderPlace(target)}
            {renderMap(target)}
          </div>
        </Margin>
      ) : null}
    </>
  );

  const handleClickApply = () => {
    setOpenConfirmModal(false);
    applyUserToMa({ maId: id });
  };

  const renderApply = () => {
    const userMaId = userMa ? String(userMa.id) : null;
    const maId = String(id);

    if (!isLogin()) return null;
    if (userMaId && userMaId !== maId) return null;

    return (
      <>
        <Margin top="24">
          <Button
            disabled={userMaId && userMaId === maId}
            auto="xl"
            onClick={() => setOpenConfirmModal(true)}
          >
            {t('page.singleMa.applyToJoinButton')}
          </Button>
        </Margin>
        <Modal
          heading={t('page.singleMa.applyToJoinModalTitle')}
          isOpen={openConfirmModal}
          confirm={handleClickApply}
          cancel={() => setOpenConfirmModal(false)}
          onRequestClose={() => setOpenConfirmModal(false)}
        />
      </>
    );
  };

  return (
    <>
      <PageContent>
        {renderAuthLock()}
        <Container>
          <Breadcrumbs items={breadcrumbs} />
          <Alerts type="ma" />
          <Row>
            <Col size={{ xl: 2 / 3 }}>
              <Margin bottom="24">
                <HeaderBrand thumbnail={getAvatar(photo, 'ma')}>
                  <Heading as="h1" size="h1" firstLine>
                    {title}
                  </Heading>
                </HeaderBrand>
              </Margin>
              <Margin bottom={{ xs: 32, xl: 48 }}>
                {description && (
                  <Cms>
                    <p dangerouslySetInnerHTML={{ __html: newLineToBr(description) }} />
                  </Cms>
                )}
                {/* {renderApply()} */}
              </Margin>

              {renderDescription(
                t('page.singleMa.individualMemberships'),
                individualMembershipsDescription,
                individualMembershipsWebsite && (
                  <a
                    className="link link--16 word-break"
                    href={makeUrlAbsolute(individualMembershipsWebsite)}
                  >
                    {individualMembershipsWebsite}
                  </a>
                ),
              )}
              {renderDescription(
                t('page.singleMa.organisationMemberships'),
                organisationMembershipsDescription,
              )}

              <Margin bottom={{ xs: 32, xl: 48 }}>
                <HeadingWrapper>
                  <Heading as="h2" size="h4">
                    {t('page.singleMa.eventsTitle')}
                  </Heading>
                  <Link to="/events" className="link uppercase">
                    {t('global.seeAll')}
                  </Link>
                </HeadingWrapper>
                <BoxList type="event" items={events} alert={t('global.noResultsEvents')} />
              </Margin>
            </Col>

            <Margin as={Col} size={{ xl: 1 / 3 }} top={{ xs: 32, xl: 0 }}>
              {renderContact('ma')}
              {renderContact('cb')}

              {youngCrew ? (
                <Margin top="32">
                  <Margin bottom="16">
                    <Heading as="h3" size="h5">
                      {t('page.singleMa.connectedYoungCrew')}
                    </Heading>
                  </Margin>
                  <ThumbnailCard
                    thumbnail={youngCrew.photo}
                    title={youngCrew.name}
                    link={`/ipma-young-crews/${youngCrew.id}/${parseUrlTitle(youngCrew.name)}`}
                  />
                </Margin>
              ) : null}

              {usersRole && !!usersRole.length ? (
                <Margin top="32">
                  {usersRole.map((item) => (
                    <Margin top="32">
                      <UserList title={`${item.name}:`} users={item.items} />
                    </Margin>
                  ))}
                </Margin>
              ) : null}
            </Margin>
          </Row>
        </Container>
      </PageContent>
    </>
  );
};

View.propTypes = {
  loadView: PropTypes.func.isRequired,
  removeView: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }).isRequired,
  applyUserToMa: PropTypes.func.isRequired,
  userMa: PropTypes.shape({
    id: PropTypes.number,
  }),
  view: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    individualMembershipsDescription: PropTypes.string,
    individualMembershipsWebsite: PropTypes.string,
    organisationMembershipsDescription: PropTypes.string,
    photo: PropTypes.string,
    usersRole: globalPropTypes.UserListPropsTypes,
    events: PropTypes.arrayOf(PropTypes.shape({})),
    youngCrew: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.string,
    }),
    ma: PropTypes.shape({
      place: PropTypes.string,
      lat: PropTypes.number,
      long: PropTypes.number,
      phone: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
    }),
    cb: PropTypes.shape({
      place: PropTypes.string,
      lat: PropTypes.number,
      long: PropTypes.number,
      phone: PropTypes.string,
      email: PropTypes.string,
      website: PropTypes.string,
    }),
  }),
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

View.defaultProps = {
  userMa: {},
  view: {
    title: '',
    description: '',
    individualMembershipsDescription: '',
    individualMembershipsWebsite: '',
    organisationMembershipsDescription: '',
    phone: '',
    email: '',
    website: '',
    photo: '',
    place: '',
    lat: '',
    lng: '',
    usersRole: [],
    events: [],
    youngCrew: {
      id: undefined,
      name: '',
      photo: '',
    },
    ma: {
      place: '',
      lat: '',
      long: '',
      phone: '',
      email: '',
      website: '',
    },
    cb: {
      place: '',
      lat: '',
      long: '',
      phone: '',
      email: '',
      website: '',
    },
  },
  breadcrumbs: null,
};

const mapStateToProps = ({
  user: {
    user: { memberAssociation },
  },
  list: {
    ma: { view },
  },
}) => ({
  view,
  userMa: memberAssociation,
});

const mapDispatchToProps = (dispatch) => ({
  loadView: (payload, history) => dispatch(loadListViewCreator('ma', payload, history)),
  removeView: () => dispatch(removeListViewCreator('ma')),
  applyUserToMa: (payload) => dispatch(applyUserToMaCreator(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
