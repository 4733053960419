import { takeLatest, put, select, call, cancelled } from 'redux-saga/effects';
import * as listService from 'api/services/list';
import * as ycService from 'api/services/yc';
import * as organisationService from 'api/services/organisation';
import {
  removeLoadingCreator,
  setNewLoadingCreator,
  setListCreator,
  setListPagesCreator,
  setListViewCreator,
} from 'store/actionsCreators';
import { LOAD_LIST, LOAD_LIST_VIEW } from 'store/constants';
import { get, map } from 'lodash';
import {
  parseDate,
  parseParams,
  parsePlace,
  parseBoxesList,
  parseUserListItem,
  checkPermissions,
  isDateAfter,
  parseUserList,
  parseUrlTitle,
} from 'helpers';
import moment from 'moment';

function loadList({ listType, options }) {
  switch (listType) {
    case 'courses':
      return loadListCourses();
    case 'glossary':
      return loadListGlossaries();
    case 'events':
      return loadListEvents();
    case 'ma':
      return loadListMa();
    case 'organisations':
      return loadListOrganisations();
    case 'yc':
      return loadListYc();
    case 'projects':
      return loadListProjects(options ? options.type : null);
    default:
      return null;
  }
}

function loadListView(view) {
  switch (view.listType) {
    case 'courses':
      return loadListViewCourses(view);
    case 'events':
      return loadListViewEvents(view);
    case 'ma':
      return loadListViewMa(view);
    case 'organisations':
      return loadListViewOrganisations(view);
    case 'yc':
      return loadListViewYc(view);
    case 'projects':
      return loadListViewProjects(view);
    case 'projectRole':
      return loadListViewProjectRole(view);
    default:
      return null;
  }
}

function* loadListGlossaries() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_GLOSSARIES' }));

    const [filters, page, perPage, sort] = yield select(({ list: { glossary } }) => [
      glossary.filters,
      glossary.page,
      glossary.perPage,
      glossary.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListGlossary, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, totalItems },
          },
        },
      } = yield response.json();

      const glossary = parseBoxesList('glossary', items);

      yield put(setListCreator('glossary', glossary));
      yield put(setListPagesCreator('glossary', Math.ceil(totalItems / perPage)));
    }
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_GLOSSARIES' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_GLOSSARIES' }));
    console.log('[GET] list courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_GLOSSARIES' }));
  }
}

function* loadListCourses() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_COURSES' }));

    const [filters, page, perPage, sort] = yield select(({ list: { courses } }) => [
      courses.filters,
      courses.page,
      courses.perPage,
      courses.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListCourses, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, totalItems },
          },
        },
      } = yield response.json();

      const courses = parseBoxesList('courses', items);

      yield put(setListCreator('courses', courses));
      yield put(setListPagesCreator('courses', Math.ceil(totalItems / perPage)));
    }
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_COURSES' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_COURSES' }));
    console.log('[GET] list courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_COURSES' }));
  }
}

function* loadListViewCourses({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const { userPermissions } = yield select(({ user }) => user);
    const response = yield call(listService.getListViewCourses, id);

    if (response.ok) {
      const {
        results: {
          result: { entity: item },
        },
      } = yield response.json();

      let organisator = null;

      if (item.organization) {
        organisator = {
          id: get(item, 'organization.id') || null,
          title: get(item, 'organization.name') || '',
          url: `/organisations/${get(item, 'organization.id') || null}`,
          thumbnail: get(item, 'organization.photo') || '',
          place: parsePlace(
            get(item, 'organization.address.city') || '',
            get(item, 'organization.country.name') || '',
          ),
          user: get(item, 'organization.contactPerson') || '',
          description: get(item, 'organization.shortDescription') || '',
          phone: get(item, 'organization.telephone') || '',
          email: get(item, 'organization.email') || '',
          website: get(item, 'organization.website') || '',
          lat: get(item, 'organization.address.lat') || '',
          lng: get(item, 'organization.address.lang') || '',
          courses: get(item, 'organization.countCourses')
            ? String(get(item, 'organization.countCourses'))
            : '',
        };
      }

      const course = {
        title: item.name || '',
        description: item.description || '',
        instructionFormat: [
          ...map(item.instructionFormat, 'name'),
          ...[get(item, 'customInstructionFormat') || null],
        ]
          .filter((el) => !!el)
          .join(', '),
        competencies: [
          ...map(item.competency, 'name'),
          ...map(item.peopleCompetency, 'name'),
          ...map(item.practiceCompetency, 'name'),
          ...map(item.customCourseCompetencies, 'name'),
        ]
          .filter((el) => !!el)
          .join(', '),

        tags: map(item.tags, 'name') || [],
        keyMaterials: get(item, 'courseKeyMaterials') || [],
        learningOutcomes: get(item, 'courseLearningOutcomes') || [],
        place: parsePlace(get(item, 'address.city') || '', get(item, 'address.country.name') || ''),
        countryCode: get(item, 'address.country.code') || '',
        startDate: parseDate(item.startDate, 'DD MMMM YYYY'),
        endDate: parseDate(item.endDate, 'DD MMMM YYYY'),
        duration: item.duration || '',
        deliveryMode: map(item.deliveryMode, 'name')
          .filter((el) => !!el)
          .join(', '),
        courseType: map(item.courseType, 'name')
          .filter((el) => !!el)
          .join(', '),
        signature: item.uuid,
        programme: item.programmeDescription,
        phone: get(item, 'phone') || get(organisator, 'phone') || '',
        email: get(item, 'contactEmail') || get(organisator, 'email') || '',
        website: get(item, 'website') || get(organisator, 'website') || '',
        lat: get(item, 'address.lat') || get(organisator, 'lat') || '',
        lng: get(item, 'address.lang') || get(organisator, 'lng') || '',
        organisator,
        keyExperts: map(item.keyExperts, parseUserListItem),
        assessors: [],
        personResponsible: map(
          item.responsiblePerson ? [item.responsiblePerson] : [],
          parseUserListItem,
        ),

        timezone: get(item, 'timezone.gmt') || '',
        isPublished: get(item, 'courseState.name') === 'published',
        isOutdated: item.endDate ? isDateAfter(moment(), item.endDate) : false,
        mainLanguage: item.mainLanguage,
        secondaryLanguage: item.secondaryLanguage,
        courseGeneralStatus: item.courseGeneralStatus,
      };

      if (item.isSubcourse) {
        course.title = item.originalCourseName || '';
        course.subtitle = item.name || '';
      }

      if (
        userPermissions &&
        checkPermissions(
          {
            roles: ['ma_courses_materials'],
          },
          userPermissions,
        )
      ) {
        const filesKeyExperts = map(item.keyExperts, (n) => n.keyExpertCv || null);
        const fileSelfAssesorCv = item.selfAssesorCvFile || null;

        course.files = [
          item.courseFile1File,
          item.courseFile2File,
          item.courseProgrammeStatementFile,
          ...filesKeyExperts,
          fileSelfAssesorCv,
        ].filter((el) => !!el);
      }

      yield put(setListViewCreator('courses', course));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view courses error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListEvents() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_EVENTS' }));

    const [filters, page, perPage, sort] = yield select(({ list: { events } }) => [
      events.filters,
      events.page,
      events.perPage,
      events.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListEvents, params);
    if (response.ok) {
      const {
        results: {
          result: { items, totalItems },
        },
      } = yield response.json();

      const events = parseBoxesList('events', items);

      yield put(setListCreator('events', events));
      yield put(setListPagesCreator('events', Math.ceil(totalItems / perPage)));
    }
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_EVENTS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_EVENTS' }));
    console.log('[GET] list events error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_EVENTS' }));
  }
}

function* loadListViewEvents({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewEvents, id);

    if (response.ok) {
      const {
        results: {
          result: { event: item, organisator },
        },
      } = yield response.json();

      let organisatorData = null;
      let organisatorUrl = '';
      switch (organisator.type) {
        case 'YC':
          organisatorUrl = `/ipma-young-crews/${organisator.id || null}/${parseUrlTitle(
            organisator.name,
          )}`;
          break;
        case 'MA':
          organisatorUrl = `/ipma-associations/${organisator.id || null}/${parseUrlTitle(
            organisator.name,
          )}`;
          break;
        case 'IPMA':
          organisatorUrl = '';
          break;
        default:
          organisatorUrl = '';
      }
      if (organisator) {
        organisatorData = {
          id: organisator.id || null,
          title: organisator.name || '',
          url: organisatorUrl,
          thumbnail: organisator.photo || '',
          place: parsePlace(
            get(organisator, 'address.city') || '',
            get(organisator, 'country.name') || '',
          ),
          description: organisator.shortDescription || '',
          website: organisator.website || '',
          lat: get(organisator, 'address.lat') || '',
          lng: get(organisator, 'address.lang') || '',
          events: organisator.countEvents ? String(organisator.countEvents) : '',
        };
      }

      const event = {
        title: item.name || '',
        description: item.fullDescription || '',
        nationalName: item.nationalName || '',
        tags: map(item.tags, 'name') || [],
        place: parsePlace(get(item, 'address.city') || '', get(item, 'address.country.name') || ''),
        countryCode: get(item, 'address.country.code') || '',
        startDate: parseDate(item.dateFrom, 'DD MMMM YYYY'),
        endDate: parseDate(item.dateTo, 'DD MMMM YYYY'),
        deliveryMode: get(item, 'modeOfDelivery.name') || '',
        timeOfEvent: get(item, 'timeOfEvent') || '',
        website: item.website || get(organisatorData, 'website') || '',
        lat: get(item, 'address.lat') || get(organisatorData, 'lat') || '',
        lng: get(item, 'address.lang') || get(organisatorData, 'lng') || '',
        mainLanguage: get(item, 'mainLanguage.name') || '',
        secondaryLanguage: get(item, 'secondaryLanguage.name') || '',
        price: [item.price || '', get(item, 'currency.sign') || get(item, 'currency.code')]
          .filter((el) => !!el)
          .join(' '),
        timezone: get(item, 'timezone.gmt') || '',
        ticketsLink: get(item, 'ticketsLink') || '',
        contactEmail: get(item, 'contactEmail') || '',
        venueName: get(item, 'venueName') || '',
        facebookLink: get(item, 'facebookLink') || '',
        linkedinLink: get(item, 'linkedinLink') || '',
        twitterLink: get(item, 'twitterLink') || '',
        instagramLink: get(item, 'instagramLink') || '',
        youtubeLink: get(item, 'youtubeLink') || '',
        organisator: organisatorData,
        customLinks: get(item, 'eventCustomLinks') || [],
        photo: get(item, 'photo') || [],
      };

      yield put(setListViewCreator('events', event));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view events error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListMa() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_MA' }));

    const [filters, page, perPage, sort] = yield select(({ list: { ma } }) => [
      ma.filters,
      ma.page,
      ma.perPage,
      ma.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListMa, params);
    if (response.ok) {
      const {
        results: {
          result: { items, totalItems },
        },
      } = yield response.json();

      const ma = parseBoxesList('ma', items);

      yield put(setListCreator('ma', ma));
      yield put(setListPagesCreator('ma', Math.ceil(totalItems / perPage)));
    }

    yield put(removeLoadingCreator({ type: 'LOAD_LIST_MA' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_MA' }));
    console.log('[GET] list ma error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_MA' }));
  }
}

function* loadListViewMa({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewMa, id);

    if (response.ok) {
      const {
        results: {
          result: { ma: item, userRoleOfMa: usersRole, upcomingEventsByMemberAssociation: events },
        },
      } = yield response.json();

      const ma = {
        title: item.name || '',
        description: item.fullDescription || '',
        individualMembershipsDescription: item.individualMembershipsDescription || '',
        individualMembershipsWebsite: item.individualMembershipsWebsite || '',
        organisationMembershipsDescription: item.organisationMembershipsDescription || '',
        photo: item.photo || '',
        usersRole: parseUserList(usersRole),
        events: parseBoxesList('events', events),
        youngCrew: item.youngCrew,
        ma: {
          place: parsePlace(
            get(item, 'address.city') || '',
            get(item, 'address.country.name') || '',
          ),
          lat: get(item, 'address.lat') || '',
          lng: get(item, 'address.lang') || '',
          phone: item.telephone || '',
          email: item.email || '',
          website: item.website || '',
        },
        cb: {
          place: parsePlace(
            get(item, 'cbAddress.city') || '',
            get(item, 'cbAddress.country.name') || '',
          ),
          lat: get(item, 'cbAddress.lat') || '',
          lng: get(item, 'cbAddress.lang') || '',
          phone: item.cbTelephone || '',
          email: item.cbEmail || '',
          website: item.cbWebsite || '',
        },
        yc: {
          place: parsePlace(
            get(item, 'ycAddress.city') || '',
            get(item, 'ycAddress.country.name') || '',
          ),
          lat: get(item, 'ycAddress.lat') || '',
          lng: get(item, 'ycAddress.lang') || '',
          phone: item.ycTelephone || '',
          email: item.ycEmail || '',
          website: item.ycWebsite || '',
        },
      };

      yield put(setListViewCreator('ma', ma));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view ma error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListOrganisations() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_ORGANISATIONS' }));

    const [filters, page, perPage, sort] = yield select(({ list: { organisations } }) => [
      organisations.filters,
      organisations.page,
      organisations.perPage,
      organisations.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListOrganisations, params);
    if (response.ok) {
      const {
        results: {
          result: {
            entity: { items, totalItems },
          },
        },
      } = yield response.json();

      const organisations = parseBoxesList('organisations', items);

      yield put(setListCreator('organisations', organisations));
      yield put(setListPagesCreator('organisations', Math.ceil(totalItems / perPage)));
    }

    yield put(removeLoadingCreator({ type: 'LOAD_LIST_ORGANISATIONS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_ORGANISATIONS' }));
    console.log('[GET] list ma error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_ORGANISATIONS' }));
  }
}

function* loadListViewOrganisations({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewOrganisations, id);
    let courses = [];
    let coursesUpcoming = [];
    let coursesRunning = [];

    if (response.ok) {
      const {
        results: { result: item },
      } = yield response.json();

      const coursesResponse = yield call(organisationService.getOrganisationCoursesSort, id, {
        'sort-by': 'createdAt.desc',
      });

      if (coursesResponse.ok) {
        const {
          results: {
            result: {
              entity: {
                courses: coursesListTemplates,
                coursesUpcoming: coursesListUpcoming,
                coursesRunning: coursesListRunning,
              },
            },
          },
        } = yield coursesResponse.json();

        courses = parseBoxesList('courses', coursesListTemplates);
        coursesUpcoming = parseBoxesList('courses', coursesListUpcoming);
        coursesRunning = parseBoxesList('courses', coursesListRunning);
      }

      const organisation = {
        title: item.name || '',
        description: item.fullDescription || '',
        phone: item.telephone || '',
        email: item.email || '',
        website: item.website || '',
        photo: item.photo || '',
        place: parsePlace(get(item, 'address.city') || '', get(item, 'address.country.name') || ''),
        lat: get(item, 'address.lat') || '',
        lng: get(item, 'address.lang') || '',
        personResponsible: item.personResponsible
          ? map([item.personResponsible], parseUserListItem)
          : [],
        organizationRoles: item.organizationRoles ? parseUserList(item.organizationRoles) : [],
        courses,
        coursesUpcoming,
        coursesRunning,
      };

      yield put(setListViewCreator('organisations', organisation));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view organisations error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListYc() {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_YC' }));

    const [filters, page, perPage, sort] = yield select(({ list: { yc } }) => [
      yc.filters,
      yc.page,
      yc.perPage,
      yc.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    const response = yield call(listService.getListYc, params);
    if (response.ok) {
      const {
        results: {
          result: { items, totalItems },
        },
      } = yield response.json();

      const yc = parseBoxesList('yc', items);

      yield put(setListCreator('yc', yc));
      yield put(setListPagesCreator('yc', Math.ceil(totalItems / perPage)));
    }

    yield put(removeLoadingCreator({ type: 'LOAD_LIST_YC' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_YC' }));
    console.log('[GET] list yc error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_YC' }));
  }
}

function* loadListViewYc({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewYc, id);

    if (response.ok) {
      const {
        results: {
          result: { yc: item, userRoleOfYc: usersRole, upcomingEventsByYoungCrew: events },
        },
      } = yield response.json();

      const yc = {
        title: item.name || '',
        description: item.fullDescription || '',
        youngCrewMembershipsDescription: item.youngCrewMembershipsDescription || '',
        photo: item.photo || '',
        usersRole: parseUserList(usersRole),
        events: parseBoxesList('events', events),
        memberAssociation: item.memberAssociation,
        yc: {
          place: parsePlace(
            get(item, 'address.city') || '',
            get(item, 'address.country.name') || '',
          ),
          lat: get(item, 'address.lat') || '',
          lng: get(item, 'address.lang') || '',
          phone: item.telephone && item.telephone !== 'null' ? item.telephone : '',
          email: item.email && item.email !== 'null' ? item.email : '',
          website: item.website && item.website !== 'null' ? item.website : '',
          contactPerson: item.contactPerson || '',
        },
      };

      yield put(setListViewCreator('yc', yc));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view yc error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListProjects(type) {
  try {
    yield put(setNewLoadingCreator({ type: 'LOAD_LIST_PROJECTS' }));

    const [filters, page, perPage, sort] = yield select(({ list: { projects } }) => [
      projects.filters,
      projects.page,
      projects.perPage,
      projects.sort,
    ]);

    const params = parseParams({
      maxResult: perPage,
      page,
      'sort-by': sort,
      ...filters,
    });

    if (type === 'projects') {
      const response = yield call(listService.getListProjects, params);
      if (response.ok) {
        const {
          results: {
            result: { items, totalItems },
          },
        } = yield response.json();

        const yc = parseBoxesList('project', items);

        yield put(setListCreator('projects', yc));
        yield put(setListPagesCreator('projects', Math.ceil(totalItems / perPage)));
      }
    } else if (type === 'positions') {
      const response = yield call(listService.getListProjectsPositions, params);
      if (response.ok) {
        const {
          results: {
            result: { items, totalItems },
          },
        } = yield response.json();

        const yc = parseBoxesList('project-role', items);

        yield put(setListCreator('projects', yc));
        yield put(setListPagesCreator('projects', Math.ceil(totalItems / perPage)));
      }
    }

    yield put(removeLoadingCreator({ type: 'LOAD_LIST_PROJECTS' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'LOAD_LIST_PROJECTS' }));
    console.log('[GET] list projects error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'LOAD_LIST_PROJECTS' }));
  }
}

function* loadListViewProjects({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(listService.getListViewProjects, id);

    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      const item = result[0];
      const organisator = item.youngCrew;
      const roles = item.ycProjectRoles;
      let organisatorData = null;
      let rolesData = [];

      if (organisator) {
        organisatorData = {
          id: organisator.id || null,
          title: organisator.name || '',
          url: `/ipma-young-crews/${organisator.id || null}/${parseUrlTitle(organisator.name)}`,
          thumbnail: organisator.photo || '',
          place: parsePlace(
            get(organisator, 'address.city') || '',
            get(organisator, 'address.country.name') || get(organisator, 'country.name') || '',
          ),
          description: organisator.shortDescription || '',
          lat: get(organisator, 'address.lat') || '',
          lng: get(organisator, 'address.lang') || '',
        };
      }
      if (roles) {
        rolesData = parseBoxesList('project-role', roles);
      }

      const project = {
        title: item.name || '',
        description: item.description || '',
        place: parsePlace(
          get(item, 'location.city') || '',
          get(item, 'location.country.name') || '',
        ),
        countryCode: get(item, 'location.country.code') || '',
        countryName: get(item, 'location.country.name') || '',
        startDate: parseDate(item.dateFrom, 'DD MMMM YYYY'),
        endDate: parseDate(item.dateTo, 'DD MMMM YYYY'),
        website: item.customUrl,
        lat: get(item, 'location.lat') || get(organisatorData, 'lat') || '',
        lng: get(item, 'location.lang') || get(organisatorData, 'lng') || '',
        mainLanguage: get(item, 'mainLanguage.name') || '',
        secondaryLanguage: get(item, 'secondaryLanguage.name') || '',
        level: `${item.level.charAt(0).toUpperCase()}${item.level.slice(1).toLowerCase()}`,
        type: `${item.type.charAt(0).toUpperCase()}${item.type.slice(1).toLowerCase()}`,
        organisator: organisatorData,
        roles: rolesData,
      };

      yield put(setListViewCreator('projects', project));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view projects error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

function* loadListViewProjectRole({ payload: id, history }) {
  try {
    yield put(setNewLoadingCreator({ type: 'GLOBAL' }));

    const response = yield call(ycService.getProjectRole, id);

    if (response.ok) {
      const {
        results: { result },
      } = yield response.json();
      const item = result[0];
      const project = item.ycProject;
      let projectData = null;
      if (project) {
        projectData = {
          id: project.id || null,
          projectStatus: project.projectStatus || null,
          title: project.name || '',
          url: `/projects/${project.id || null}/${parseUrlTitle(project.name)}`,
          thumbnail: project.photo || '',
          place: parsePlace(
            get(project, 'location.city') || '',
            get(project, 'location.country.name') || get(project, 'country.name') || '',
          ),
          description: project.shortDescription || '',
          lat: get(project, 'address.lat') || '',
          lng: get(project, 'address.lang') || '',
        };
      }

      const projectRole = {
        title: item.name || '',
        type: item.type || '',
        occupation: item.occupation || '',
        careerLevel: get(item, 'careerLevel.value') || '',
        ycProjectRoleType: get(item, 'ycProjectRoleType.value') || '',
        roleDescription: item.roleDescription,
        responsibilities: item.responsibilities,
        qualifications: item.qualifications,
        closingInformations: item.closingInformations,
        skills: map(item.skills, 'value') || [],
        project: projectData,
        positionTimeStatus: item.positionTimeStatus,
        status: item.status,
        cvRequired: item.cvFileRequired,
        letterRequired: item.letterFileRequired,
      };

      yield put(setListViewCreator('projects', projectRole));
    }
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  } catch (error) {
    yield put(removeLoadingCreator({ type: 'GLOBAL' }));

    if (history) {
      history.push('/404');
    }

    console.log('[GET] list view projects error: ', error);
  } finally {
    if (yield cancelled()) yield put(removeLoadingCreator({ type: 'GLOBAL' }));
  }
}

export default function* watchList() {
  yield takeLatest(LOAD_LIST, loadList);
  yield takeLatest(LOAD_LIST_VIEW, loadListView);
}
